<template>
  <div>
    <v-menu rounded="b-lg" bottom offset-y  width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab dark small fixed right top color="pediidos" v-on="on" v-bind="attrs" ><v-icon>mdi-translate</v-icon></v-btn>
    </template>
      <v-list dense dark color="pediidos lighten-1" >
        <v-list-item-group mandatory v-model="indexLocale" >
          <v-list-item color="white" v-for="(item, index) in locales" :key="index" @click="chageLocate(item.locale)" >
            <v-list-item-content>
              <v-avatar tile >
                <v-img :src="item.ico3"></v-img>
              </v-avatar>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <div class="text-center my-5 ">
      <img width="300px" :src="logo_url">
    </div>
    <v-container fill-height fluid class="m-auto mt-10" style="width: 95%;">
      <v-row>
        <!-- <v-col cols="12" md="6" class="mb-4 mb-md-0">
          <img class="login-logo" width="100%" src="https://firebasestorage.googleapis.com/v0/b/pediidos-f9d72.appspot.com/o/misitio%2Flogos-pediidos%2Fmacbook.png?alt=media" />
        </v-col> -->
        <v-col cols="12" md="12" class="mb-10">
          <div class="mb-3 pediidos--text text-lg-h4 text-md-h4 text-sm-h5" >{{$t('login.wellcome_title')}} {{company_name}}</div>
          <p class="text-title grey--text text--darken-1" >{{$t('login.msg_wellcome_message')}}</p>
          <v-form ref="loginForm" lazy-validation>
            <v-text-field
              type="email"
              v-model="credenciales.email"
              @keyup.enter="loginBackend()"
              :rules="loginRules.email"
              append-icon="mdi-at"
              :label="this.$t('email')"
              :placeholder="this.$t('email')"
              outlined
              color="pediidos">
            </v-text-field>
            <v-text-field
              v-model="credenciales.password"
              @keyup.enter="loginBackend()"
              :rules="loginRules.password"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPass = !showPass"
              :label="this.$t('password')"
              :placeholder="this.$t('password')"
              :type="showPass ? 'text' : 'password'"
              outlined
              color="pediidos">
            </v-text-field>
          </v-form>

          <v-dialog v-model="dialogResetPassword" persistent max-width="600px" >
            <template  v-slot:activator="{ on, attrs }">
                <v-btn class="mt-n3" plain color="pediidos" small block  v-bind="attrs" v-on="on" >{{$t('login.btn_forgot_password')}}</v-btn>
            </template>
            <v-card>
              <v-card-title class="text-center align-center justify-center " >{{$t('login.qn_forgot_password')}}</v-card-title>
              <v-card-text class="text-center align-center justify-center " >{{$t('login.msg_forgot_password')}} </v-card-text>
              <v-form class="ma-0 pa-0" ref="resetForm" lazy-validation>
                <v-text-field
                  class="px-5"
                  type="email"
                  v-model="inputResetPassword"
                  :rules="rulesResetPassword"
                  :label="this.$t('email')"
                  :placeholder="this.$t('email')"
                  prepend-icon="mdi-at"
                  autocomplete="off"
                  outlined
                  required
                  color="pediidos">
                </v-text-field>
              </v-form>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="pediidos" text @click="clearResetPassword()">{{$t('cancel')}}</v-btn>
                <v-btn color="pediidos" text @click="resetPassword()">{{$t('confirm')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn class="mt-3" type="submit" rounded block dark color="pediidos" @click="loginBackend(credenciales)">{{$t('_login')}}</v-btn>
          <v-dialog  v-model="dialogSignup" persistent fullscreen transition="dialog-top-transition" >
            <template v-slot:activator="{ on, attrs }" v-if="allow_signup">
              <v-btn class="mt-5" block rounded dark color="success" v-bind="attrs" v-on="on"  >{{$t('login.btn_signup')}}</v-btn>
            </template>
            <v-card class="mx-auto pa-5 mt-5" max-width="1264" elevation="4">
              <v-toolbar dark color="pediidos">
                <v-btn icon dark @click="clearSignup()" ><v-icon>mdi-close</v-icon></v-btn>
                <v-toolbar-title>{{$t('login.create_account')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text :disabled="val_btn_signup" @click="sendSignup()" >{{$t('send')}}</v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-title style="word-break: normal;" class="headline text-center align-center justify-center mb-5">{{$t('login.title_create_account')}}</v-card-title>
                <v-form class="px-5 pb-5 text-center" ref="signupForm" lazy-validation>
                  <v-row>
                    <v-col class="py-0 " cols="12" md="6">
                    <v-text-field
                      color="pediidos"
                      :label="this.$t('name')"
                      :placeholder="this.$t('name')"
                      v-model.trim="signup.name"
                      :rules="rules.name"
                      counter="30"
                      maxlength="30"
                      prepend-icon="mdi-account"
                      autocomplete="off"
                      outlined
                    ></v-text-field>
                    </v-col>
                    <v-col class="py-0 " cols="12" md="6">
                    <v-text-field
                      color="pediidos"
                      :label="this.$t('email')"
                      :placeholder="this.$t('email')"
                      v-model.trim="signup.email"
                      :rules="rules.email"
                      counter="150"
                      maxlength="150"
                      prepend-icon="mdi-at"
                      autocomplete="off"
                      outlined
                    ></v-text-field>
                    </v-col>
                    <v-col class="py-0 " cols="12" md="6">
                    <v-text-field
                      color="pediidos"
                      :label="this.$t('password')"
                      :placeholder="this.$t('password')"
                      v-model.trim="signup.password1"
                      :rules="rules.password1"
                      :type="signup.showPass ? 'text' : 'password'"
                      counter="30"
                      maxlength="30"
                      prepend-icon="mdi-lock"
                      :append-icon="signup.showPass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="signup.showPass = !signup.showPass"
                      autocomplete="off"
                      outlined
                    ></v-text-field>
                    </v-col>
                    <v-col class="py-0 " cols="12" md="6">
                    <v-text-field
                      color="pediidos"
                      :label="this.$t('confirm_password')"
                      :placeholder="this.$t('confirm_password')"
                      v-model.trim="signup.password2"
                      :rules="rules.password2"
                      :type="signup.showPass ? 'text' : 'password'"
                      counter="30"
                      maxlength="30"
                      prepend-icon="mdi-lock-alert"
                      :append-icon="signup.showPass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="signup.showPass = !signup.showPass"
                      autocomplete="off"
                      outlined
                    ></v-text-field>
                    </v-col>
                    <v-col class="py-0 " cols="12" md="6">
                    <v-text-field
                      color="pediidos"
                      :label="this.$t('phone')"
                      :placeholder="this.$t('phone')"
                      v-model.trim="signup.phone"
                      :rules="rules.phone"
                      counter="15"
                      maxlength="15"
                      prepend-icon="mdi-phone"
                      autocomplete="off"
                      outlined
                    ></v-text-field>
                    </v-col>
                    <v-col class="py-0 " cols="12" md="6">
                    <v-text-field
                      color="pediidos"
                      :label="this.$t('dni')"
                      :placeholder="this.$t('dni')"
                      v-model.trim="signup.identification"
                      :rules="rules.identification"
                      counter="15"
                      maxlength="15"
                      prepend-icon="mdi-fingerprint"
                      autocomplete="off"
                      outlined
                    ></v-text-field>
                    </v-col>
                    <v-col class="py-0 " cols="12" md="6">
                    <v-text-field
                      color="pediidos"
                      :label="this.$t('reference_code')"
                      :placeholder="this.$t('reference_code')"
                      v-model.trim="signup.ref_code"
                      counter="10"
                      maxlength="18"
                      prepend-icon="mdi-tag"
                      autocomplete="off"
                      outlined
                    ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script type="text/javascript">
  import Api from '@/helpers/api.js';
  import auth from '@/firebase/auth.js';
  import jwt from 'jsonwebtoken';
  import Floppy from '@/helpers/IndexedDB.js';
  import { mapMutations, mapState } from 'vuex';
  import * as constants from '@/constants';
  export default {
    name: 'Login',
    data() {
      return {
        indexLocale: 0,
        locales: constants.SUPPORTED_LOCALE,
        showPass: false,
        credenciales: {
            email: '',
            password: '',
        },
        loginRules: {
          email: [v => !! v || this.$t('error.empty_email'), v => /.+@.+\..+/.test(v) || this.$t('error.invalid_email'),],
          password: [v => !! v || this.$t('error.empty_password') , v => /^.{6,}$/.test(v) || this.$t('error.invalid_password')+' '+this.$t('error.invalid_min_password'),],
        },
        dialogResetPassword: false,
        inputResetPassword: "",
        rulesResetPassword: [v => !!v || this.$t('error.empty_email'), v => /.+@.+\..+/.test(v) || this.$t('error.invalid_email'),],
        dialogSignup: false,
        signup: {
          name: '',
          email: '',
          password1: '',
          password2: '',
          phone: '',
          identification: '',
          ref_code: '',
          showPass: false,
        },
        rules: {
          name: [v => !!v ||  this.$t('error.required_name'), v => (v && v.length <= 30 && v.length >= 5 ) || this.$t('error.length_name'),],
          email: [v => !! v || this.$t('error.required_email'), v => /.+@.+\..+/.test(v) || this.$t('error.invalid_email'),],
          password1: [v => !! v || this.$t('error.required_password'), v => /^(?=.*\d+)(?=.*[a-zA-Z])[\x20-\x7E]{6,}$/.test(v) || this.$t('error.invalid_strong_password'),],
          password2: [v => !! v || this.$t('error.required_confirm_password'),v => ( v == this.signup.password1 ) || this.$t('error.invalid_confirm_password'),],
          phone: [v => !!v || this.$t('error.required_phone'),v => (v && v.length <= 15 && v.length >= 7 ) || this.$t('error.length_phone'),],
          identification: [v => !!v || this.$t('error.required_dni'),v => (v && v.length <= 15 && v.length >= 8 ) || this.$t('error.length_dni'),],
        },
        logo_url: constants.LOGO_URL,
        company_name: constants.COMPANY_NAME,
        allow_signup: constants.ALLOW_SIGNUP,
      }
    },
    mounted(){
      this.chageLocate(this.$i18n.locale);
      // console.log('Current locate >>> ' , this.$i18n.locale);
    },
    methods: {
      ...mapMutations(['APP_LOADER','SET_USER', 'CLEAR_CART']),
      async loginBackend() {
        if( this.$refs.loginForm.validate() ){
          this.APP_LOADER(true);
          Api.post('users/login/',{username: this.credenciales.email, password: this.credenciales.password}).then((result)=> {
            if(result.status < 300) {
              try {
                const secretKey = 'lldtg$9(wi49j_hpv8nnqlh!cj7kmbwq0$rj7vy(b(b30vlyzj'
                // const secretKey = 'by(h$7vbmnizq($nyu#gk(c(ta5^02dyys6d6e$^nbndxj!7u0'
                const decoded = jwt.verify(result.data.token, secretKey);
                const user = decoded
                user.token = result.data.token;
                this.loginFirebase(this.credenciales, user);
              } catch(error) {
                console.log('This is error ',error);
                this.APP_LOADER(false);
                this.myNotify('error',this.$t('_error'),this.$t('error.generic'));
              }
            }else {
              this.APP_LOADER(false);
              this.myNotify('error',this.$t('_error'),this.$t('error.wrong_credentials'));
            }
          }).catch((error)=> {
            console.log('This is error ',error);
            this.APP_LOADER(false);
            this.myNotify('error',this.$t('_error'),this.$t('error.generic'));
          })
        }
      },
      async loginFirebase(credenciales, user) {
        try {
          let user_ = await auth.login(credenciales);
          if(user_) {
            if(user.profile.status === 'Confirmado' && user.profile.type_user === 'Comprador' ) {
              const user_floppy = {
                token: user.token,
                name: user.name,
                username: user.username,
                email: user.email,
                user_id: user.user_id,
                exp: user.exp,
                status: user.profile.status,
                picture: user.profile.picture,
                identification_number: user.profile.identification_number,
                birthdate: user.profile.birthdate,
                phone: user.profile.phone,
                firebase_uid: user.profile.firebase_uid,
                type_user: user.profile.type_user,
              }
              Floppy.save('users',user_floppy, user.profile.firebase_uid);
              localStorage.setItem('key',user.profile.firebase_uid);
              await Api.post('users/address/', {user_id:user.user_id}).then((result)=> {
                  if(result.status < 300) {
                  this.user.lat = result.data.lat;
                  this.user.lon = result.data.lat;
                  user_floppy['address_id'] = result.data.id;
                  user_floppy['address_alias'] = result.data.address_alias;
                  user_floppy['address_detail'] = result.data.address_detail;
                  user_floppy['lat'] = result.data.lat;
                  user_floppy['lon'] = result.data.lon;
                  user_floppy['city'] = result.data.city;
                  user_floppy['address'] = result.data.address;
                  this.CLEAR_CART({'user_id':user.user_id,'address_id':result.data.id});
                  this.SET_USER(user_floppy);
                  Floppy.update('users',user_floppy, user.profile.firebase_uid);
                }
              }).catch((error)=> {
                console.log('This is error ',error);
              }).finally(()=>{
                setTimeout(()=>{ this.$router.go(); }, 250);
              })
            }else{
              auth.logout();
              Floppy.giovanni('webapp_database_V1');
              localStorage.clear('key');
              localStorage.clear('shoppingCart');
              this.myNotify('error',this.$t('_error'),this.$t('error.wrong_credentials'));
            }
          }
        }catch(error) {
            console.log('Error firebase auth ',error);
            this.myNotify('error',this.$t('_error'),this.$t('error.wrong_credentials'));
        }finally{
          this.APP_LOADER(false);
        }
      },
      async resetPassword() {
        if( this.$refs.resetForm.validate() ){
          Api.post('users/send/email/password/',{email: this.inputResetPassword}).then((result)=> {  ///TODO:Crear esto en el back nuevo.
            if(result.status < 300) {
              this.myNotify('success',this.$t('_success'),this.$t('login.msg_reset_password'));
              this.clearResetPassword();
            }else {
              this.myNotify('error',this.$t('_error'),this.$t('error.generic'));
              console.log('This is error ',result.detail);
            }
          }).catch((error)=> {
            this.myNotify('error',this.$t('_error'),this.$t('error.generic'));
            console.log('This is error ',error);
          })
        }
      },
      async sendSignup(){
        if( this.$refs.signupForm.validate() ){
          let data = {
            name: this.signup.name,
            email: this.signup.email,
            password: this.signup.password1,
            phone: this.signup.phone,
            identification_number: this.signup.identification,
            referral_code: "0",
            birthdate: "01-01-1970",
          }
          Api.post('users/add/buyer/',data).then((result)=> {
            if(result.status < 300) {
              this.myNotify('success',this.$t('_success'),this.$t('login.msg_success_signup'));
              this.clearSignup();
            }else if(result.status == 409) {
                this.myNotify('error',this.$t('_error'),this.$t('error.user_already_exists'));
            }else{
              this.myNotify('error',this.$t('_error'),this.$t('error.generic'));
              console.log('This is error ',result.detail);
            }
          }).catch((error)=> {
            this.myNotify('error',this.$t('_error'),this.$t('error.generic'));
            console.log('This is error ',error);
          })
        }
      },
      clearResetPassword(){
        this.dialogResetPassword = false;
        this.$refs.resetForm.reset();
      },
      clearSignup(){
        this.$refs.signupForm.reset();
        this.signup.name = '';
        this.signup.email = '';
        this.signup.password1 = '';
        this.signup.password2 = '';
        this.signup.phone = '';
        this.signup.identification = '';
        this.signup.ref_code = '';
        this.signup.showPass = false;
        this.dialogSignup = false;
      },
      chageLocate(item){
        if (this.$i18n.locale !== item) {
          this.$i18n.locale = item;
          }
        for (var i = 0; i < this.locales.length; i++) {
          if (this.locales[i].locale === item){
            this.indexLocale = i;
            break
          }
        }
      },
    },
    computed: {
      ...mapState(['userLogged','user',]),
      val_btn_signup(){
        if(this.signup.name.length < 6 || this.signup.email.length < 7 || this.signup.password1.length < 7 ||
          this.signup.password2.length < 7 || this.signup.phone.length < 8 || this.signup.identification < 9 ){
          return true
        }
        return false
      },
    },
  }
</script>

<style type="text/css" scoped>
</style>